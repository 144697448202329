import { getShopDetails } from '@/services/product'
import { getOrderDetails } from '@/services/order'
import history from '@/common/data/jsonData'
import { getHistoryList } from '@/services/resource'
import createTable from '@/components/createTable'
import viewFrame from '@/components/viewFrame'
import customeTable from '@/components/customeTable'
import loading from '@/utils/loading.json'
import dialogEffect from '@/components/dialogEffect'
import jsonData from './jsonData'
import Utils from '@/utils/Utils'
// import { json } from 'express'
export default {
  components: {
    createTable,
    viewFrame,
    customeTable,
    dialogEffect
  },
  data () {
    return {
      urlParams: null,
      validateVisible: false,
      buttonObj: [],
      titleObject: {},
      custName: null,
      formData: {},
      type: '',
      productBasicViewItem: jsonData.productBasicViewItem,
      imagesViewItem: jsonData.imagesViewItem,
      shopItemStocksViewItem: jsonData.shopItemStocksViewItem,
      shopSubCategoriesViewItem: jsonData.shopSubCategoriesViewItem,
      historyTableItem: history.historyTableItem,
      dialogData: [],
      batchData: [],
      dialogVisible: false,
      historyTableData: []
    }
  },
  created () {
    const _this = this
    _this.urlParams = _this.$route.params
  },
  mounted () {
    const _this = this
    _this.init()
  },
  methods: {
    setGlobalLoading (show) {
      const _this = this
      show ? _this.$store.state.app.loading = _this.$loading(loading) : _this.$store.state.app.loading.close()
    },
    // 初始化
    async init () {
      const _this = this
      Object.assign(this.titleObject, { title: `${_this.$t('text.product')} Id: `, data: _this.urlParams.id })
      _this.setGlobalLoading(true)
	  const res = await getShopDetails(_this.urlParams.id)
	  if (res.status === 200) {
		  _this.$message({
		    type: 'success',
		    message: _this.$t('message.loadSuccess')
		  })
		  _this.custName = res.data.shop_item
		  // this.custName = obj
		  if (this.custName.images.length > 0) {
		    Object.keys(this.custName.images[0]).forEach(item => {
		      const index = this.imagesViewItem.findIndex(fditem => fditem.prop === item)
		      if (index > -1) {
		        this.imagesViewItem[index].label = this.custName.images[0][item].name
		      }
		    })
		  }
		  if (this.custName.shop_item_stocks.length > 0) {
		    Object.keys(this.custName.shop_item_stocks[0]).forEach(item => {
		      const index = this.shopItemStocksViewItem.findIndex(fditem => fditem.prop === item)
		      if (index > -1) {
		        this.shopItemStocksViewItem[index].label = this.custName.shop_item_stocks[0][item].name
		      }
		    })
		  }
		  if (this.custName.shop_sub_categories.length > 0) {
		    Object.keys(this.custName.shop_sub_categories[0]).forEach(item => {
		      const index = this.shopSubCategoriesViewItem.findIndex(fditem => fditem.prop === item)
		      if (index > -1) {
		        this.shopSubCategoriesViewItem[index].label = this.custName.shop_sub_categories[0][item].name
		      }
		    })
		  }
	  }
	  _this.setGlobalLoading(false)
      //  获取历史操作
      getHistoryList({ item_type: 'ShopItem', item_id: _this.urlParams.id }).then(res => {
        if (res.status === 200) {
          this.historyTableData = res.data.versions
        }
      })
      // if (this.custName.shop_payments.length > 0) {
      //   Object.keys(this.custName.shop_payments[0]).forEach(item => {
      //     if (item === 'member_actions') return
      //     const index = this.tableUserDataItem.findIndex(fditem => fditem.prop === item)
      //     this.tableUserDataItem[index].label = this.custName.shop_payments[0][item].name
      //   })
      // }
    },
    handleCusClick (data) {
      this.batchData = data
      if (data.extra_params && data.extra_params.length > 0) {
        this.dialogData = data.extra_params
        this.dialogVisible = true
      } else {
        Utils.custOperate(data, this)
      }
    },
    doNextConfirm (data) {
      Utils.custOperate(this.batchData, this, {}, data)
    },
    async handleClick (type, data) {
      this.$store.state.app.loading = this.$loading(loading)
      let obj = {}
      if (type?.action === 'view') {
        const res = await getOrderDetails(data.id.value)
        if (res.status === 200) {
          obj = res.data.shop_payment
        }
      }
      this.$store.state.app.loading.close()
      this.$message({
        type: 'success',
        message: this.$t('message.loadSuccess')
      })
      sessionStorage.setItem('userOrderdataOperate', JSON.stringify({ id: data && data.id ? data.id : undefined, type: type.action, obj, data }))
      this.$router.push({
        name: 'managerUserOrderView'
      })
    },
    handleShopItemStocksTableForwardToPage (data, name) {
      if (data.model_name === 'shop_item') {
        this.$router.push({
          name: 'goodsManagerProductDetail',
          params: {
            id: data.id
          }
        })
      }
      if (data.model_name === 'shop_item_stock') {
        this.$router.push({
          name: 'goodsManagerVariationsDetail',
          params: {
            id: data.id
          }
        })
      }
    },
    handleEditClick () {
      this.$router.push({
        name: 'goodsManagerProductEdit',
        params: {
          id: this.$route.params.id
        }
      })
    }
  }
}
