<template>
  <div class="managerDeliveryOrderView">
    <view-frame v-if="custName" :titleObject="titleObject" :buttonObj="buttonObj">
      <template #rightBtn>
        <el-button type="primary" size="mini" @click="handleEditClick">{{ $t('text.edit') }}</el-button>
      </template>
      <template>
        <el-card>
          <el-row>
            <el-col :span="24">
              <span class="view__text">{{ $t('text.basicInfo') }}</span>
              <el-card class="animate__animated animate__fadeInDown" shadow="hover">
                <custome-table tableType="object" :custSpan="6" :data="custName.basic_information" :dataItem="productBasicViewItem"></custome-table>
              </el-card>
            </el-col>
          </el-row>
          <el-row v-if="custName && custName.images.length > 0" class="margintop20" :gutter="20">
            <el-col :span="24" :gutter="20">
              <span class="view__text">{{ $t('text.image') }}</span>
              <el-card class="animate__animated animate__fadeInDown" shadow="hover">
                <createTable :autoWidth="true" :tableDataItem="imagesViewItem" :operateVisible="false" :tableData="custName.images"></createTable>
              </el-card>
            </el-col>
          </el-row>
          <el-row v-if="custName && custName.shop_item_stocks.length > 0" class="margintop20" :gutter="20">
            <el-col :span="24" :gutter="20">
              <span class="view__text">{{ $t('form.product.commodityInventory') }}</span>
              <el-card class="animate__animated animate__fadeInDown" shadow="hover">
                <createTable
                  :autoWidth="true"
                  :tableDataItem="shopItemStocksViewItem"
                  :operateVisible="false"
                  :tableData="custName.shop_item_stocks"
                  @forwardToPage="handleShopItemStocksTableForwardToPage"
                ></createTable>
              </el-card>
            </el-col>
          </el-row>
          <el-row v-if="custName && custName.shop_sub_categories.length > 0" class="margintop20" :gutter="20">
            <el-col :span="24" :gutter="20">
              <span class="view__text">{{ $t('form.product.shopSubCategories') }}</span>
              <el-card class="animate__animated animate__fadeInDown" shadow="hover">
                <createTable
                  :autoWidth="true"
                  :tableDataItem="shopSubCategoriesViewItem"
                  :operateVisible="false"
                  :tableData="custName.shop_sub_categories"
                ></createTable>
              </el-card>
            </el-col>
          </el-row>
          <el-card class="margintop20">
            <el-row>
              <el-col :span="24">
                <span class="view__text">{{ $t('form.common.changeHistory') }}</span>
                <el-card class="animate__animated animate__fadeInUp" shadow="hover">
                  <span class="view__text"></span>
                  <createTable :autoWidth="true" :tableDataItem="historyTableItem" :operateVisible="false" :tableData="historyTableData"></createTable>
                </el-card>
              </el-col>
            </el-row>
          </el-card>
        </el-card>
      </template>
    </view-frame>
    <dialogEffect v-if="dialogVisible" :dialogVisible.sync="dialogVisible" :data="dialogData" @doNextConfirm="doNextConfirm"></dialogEffect>
  </div>
</template>

<script>
import Logic from './index.js'
export default Logic
</script>

<style lang="scss" scope>
@import './index.scss';
</style>
